import { useEffect, useMemo, useRef } from 'react';
const UNINITIALIZED = {};
export class Timeout {
    constructor() {
        this.currentId = null;
        this.clear = () => {
            if (this.currentId !== null) {
                clearTimeout(this.currentId);
                this.currentId = null;
            }
        };
        this.disposeEffect = () => this.clear;
    }
    static create() {
        return new Timeout();
    }
    /**
     * Executes `fn` after `delay`, clearing any previously scheduled call.
     */
    start(delay, fn) {
        this.clear();
        this.currentId = setTimeout(() => {
            this.currentId = null;
            fn();
        }, delay);
    }
}
export function setRef(ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    }
    else if (ref) {
        ref.current = value;
    }
}
export default function useLazyRef(init, initArg) {
    const ref = useRef(UNINITIALIZED);
    if (ref.current === UNINITIALIZED) {
        ref.current = init(initArg);
    }
    return ref;
}
export function useForkRef(...refs) {
    /**
     * This will create a new function if the refs passed to this hook change and are all defined.
     * This means react will call the old forkRef with `null` and the new forkRef
     * with the ref. Cleanup naturally emerges from this behavior.
     */
    return useMemo(() => {
        if (refs.every((ref) => ref == null)) {
            return null;
        }
        return (instance) => {
            refs.forEach((ref) => {
                setRef(ref, instance);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, refs);
}
export function useTimeout() {
    const timeout = useLazyRef(Timeout.create).current;
    useEffect(timeout.disposeEffect, []);
    return timeout;
}
export function getTransitionProps(props, options) {
    var _a, _b;
    const { timeout, easing, style = {} } = props;
    return {
        duration: (_a = style.transitionDuration) !== null && _a !== void 0 ? _a : (typeof timeout === 'number' ? timeout : 0),
        easing: (_b = style.transitionTimingFunction) !== null && _b !== void 0 ? _b : (typeof easing === 'object' ? easing[options.mode] : easing),
        delay: style.transitionDelay,
    };
}
export const reflow = (node) => node.scrollTop;
