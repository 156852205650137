import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
/* eslint-disable react/jsx-props-no-spreading */
import MuiPopper from '@mui/material/Popper';
import MuiPaper from '@mui/material/Paper';
import MuiMenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { forwardRef, useMemo, useState, } from 'react';
import styled from '@emotion/styled';
import DropdownMenuItem from './MenuItem';
import Divider from './Divider';
import DropdownMenuHeader from './MenuHeader';
import DropdownMenuBackButton from './MenuBackButton';
import Grow from './Grow';
const MuiPaperStyled = styled(MuiPaper)((props) => ({
    marginTop: props.theme.spacing_sizes.s,
    border: `1px solid ${props.theme.customColors.menu.border}`,
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.08)',
}));
const DropdownMenuComponent = ({ isOpen = false, items, nestedVariants = {}, size = 'medium', role = 'list', onClose, keepMounted = true, disablePortal = false, transition = false, paperStyles = undefined, listStyles = undefined, popperStyles = undefined, placement = 'bottom', removePadding = false, zIndex = undefined, closeMenuAfterClick = undefined, }, anchorRef) => {
    const [activeKeys, setActiveKeys] = useState([]);
    function handleItemClick(handler) {
        return (event, key) => {
            setActiveKeys((prev) => [...prev, key]);
            if (handler) {
                handler(event, key);
            }
            if (closeMenuAfterClick && onClose) {
                onClose(event.nativeEvent);
            }
        };
    }
    const goBack = () => {
        if (activeKeys === null || activeKeys === void 0 ? void 0 : activeKeys.length) {
            setActiveKeys((prev) => {
                const newValue = prev.slice(0, prev.length - 1);
                return newValue;
            });
        }
    };
    const renderInner = useMemo(() => {
        var _a, _b;
        const currentActiveKey = activeKeys[activeKeys.length - 1];
        const activeOption = nestedVariants[currentActiveKey];
        if (currentActiveKey && ((_a = activeOption === null || activeOption === void 0 ? void 0 : activeOption.children) === null || _a === void 0 ? void 0 : _a.length)) {
            const options = ((_b = activeOption === null || activeOption === void 0 ? void 0 : activeOption.children) === null || _b === void 0 ? void 0 : _b.map((item, index) => (_createElement(DropdownMenuItem, Object.assign({}, item, { key: item.itemKey || `element-${index}`, size: item.size || size, onClick: handleItemClick(item.onClick) }))))) || [];
            if (typeof activeOption.header !== 'undefined') {
                options.unshift(_jsx(DropdownMenuHeader, { children: activeOption.header }, `header-${currentActiveKey}`));
            }
            if (activeOption.showBackButton) {
                options.push(_jsx(Divider, {}, `divider-back-${currentActiveKey}`));
                options.push(_jsx(DropdownMenuBackButton, { onClick: goBack, itemKey: `back-${currentActiveKey}`, size: size }, `back-${currentActiveKey}`));
                // options.push(<DropdownMenuItem />)
            }
            return options;
        }
        return items.map((item, index) => (_createElement(DropdownMenuItem, Object.assign({}, item, { key: item.itemKey || `element-${index}`, itemKey: item.itemKey, size: item.size || size, onClick: handleItemClick(item.onClick), removePadding: item.removePadding || removePadding }))));
    }, [nestedVariants, activeKeys, items, size]);
    const handleMenuClose = (ev) => {
        var _a;
        if ((anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) &&
            ((_a = anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) === null || _a === void 0 ? void 0 : _a.contains(ev.target))) {
            setTimeout(() => setActiveKeys([]), 500);
            return;
        }
        if (onClose) {
            onClose(ev);
        }
        setTimeout(() => setActiveKeys([]), 500);
    };
    return (_jsx(MuiPopper, { open: isOpen, popperOptions: {
            placement,
        }, style: Object.assign(Object.assign({}, (popperStyles || {})), { zIndex }), 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/destructuring-assignment
        anchorEl: anchorRef.current, placement: placement, role: role, keepMounted: keepMounted, transition: transition, disablePortal: disablePortal, children: ({ TransitionProps, placement: growPlacement }) => (_jsx(Grow, Object.assign({}, TransitionProps, { timeout: 500, style: {
                transformOrigin: growPlacement === 'bottom-start' ? 'left top' : 'left bottom',
            }, children: _jsx(MuiPaperStyled, { style: Object.assign(Object.assign({}, (paperStyles || {})), { visibility: isOpen ? 'visible' : 'hidden' }), children: _jsx(ClickAwayListener, { onClickAway: handleMenuClose, children: _jsx(MuiMenuList, { style: Object.assign({}, (listStyles || {})), children: renderInner }) }) }) }))) }));
};
const DropdownMenu = forwardRef(DropdownMenuComponent);
export default DropdownMenu;
