var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, cloneElement, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useTheme } from '@mui/material';
import { useForkRef, useTimeout, getTransitionProps, reflow, } from './utils';
const isWebKit154 = typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) &&
    /(os |version\/)15(.|_)4/i.test(navigator.userAgent);
/**
 * The Grow transition is used by the [Tooltip](/material-ui/react-tooltip/) and
 * [Popover](/material-ui/react-popover/) components.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 */
const GrowComponent = ((_a, ref) => {
    var { addEndListener, appear = true, children, easing, in: inProp, onEnter, onEntered, onEntering, onExit, onExited, onExiting, style, timeout = 'auto' } = _a, 
    // eslint-disable-next-line react/prop-types
    other = __rest(_a, ["addEndListener", "appear", "children", "easing", "in", "onEnter", "onEntered", "onEntering", "onExit", "onExited", "onExiting", "style", "timeout"]);
    const timer = useTimeout();
    const autoTimeout = useRef();
    const theme = useTheme();
    const nodeRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleRef = useForkRef(nodeRef, children === null || children === void 0 ? void 0 : children.ref, ref);
    const normalizedTransitionCallback = (callback) => (maybeIsAppearing) => {
        if (callback) {
            const node = nodeRef.current;
            // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
            if (maybeIsAppearing === undefined) {
                // eslint-disable-next-line spaced-comment, @typescript-eslint/ban-ts-comment
                // @ts-ignore
                callback(node);
            }
            else {
                callback(node, maybeIsAppearing);
            }
        }
    };
    const handleEntering = normalizedTransitionCallback(onEntering);
    const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
        reflow(node); // So the animation always start from the start.
        const { duration: transitionDuration, delay, easing: transitionTimingFunction, } = getTransitionProps({ style, timeout, easing }, {
            mode: 'enter',
        });
        let duration;
        if (timeout === 'auto') {
            duration = theme.transitions.getAutoHeightDuration(node.clientHeight);
            autoTimeout.current = duration;
        }
        else {
            duration = transitionDuration;
        }
        let nodeTransformDuration;
        if (isWebKit154) {
            nodeTransformDuration = duration;
        }
        else if (typeof duration === 'number') {
            nodeTransformDuration = duration * 0.666;
        }
        else {
            nodeTransformDuration = parseFloat(duration) * 0.666;
        }
        node.style.transition = [
            theme.transitions.create('opacity', {
                duration,
                delay,
            }),
            theme.transitions.create('transform', {
                duration: nodeTransformDuration,
                delay,
                easing: transitionTimingFunction,
            }),
        ].join(',');
        node.style.opacity = '1';
        if (onEnter) {
            onEnter(node, isAppearing);
        }
    });
    const handleEntered = normalizedTransitionCallback(onEntered);
    const handleExiting = normalizedTransitionCallback(onExiting);
    const handleExit = normalizedTransitionCallback((node) => {
        const { duration: transitionDuration, delay, easing: transitionTimingFunction, } = getTransitionProps({ style, timeout, easing }, {
            mode: 'exit',
        });
        let duration;
        if (timeout === 'auto') {
            duration = theme.transitions.getAutoHeightDuration(node.clientHeight);
            autoTimeout.current = duration;
        }
        else {
            duration = transitionDuration;
        }
        let nodeTransformDuration;
        let nodeTransformDelay;
        if (isWebKit154 || delay) {
            nodeTransformDuration = duration;
        }
        else if (typeof duration === 'number') {
            nodeTransformDelay = duration;
            nodeTransformDuration = duration;
        }
        if (typeof duration !== 'number') {
            nodeTransformDuration = parseFloat(duration);
        }
        node.style.transition = [
            theme.transitions.create('opacity', {
                duration,
                delay,
            }),
            theme.transitions.create('transform', {
                duration: nodeTransformDuration,
                delay: nodeTransformDelay,
                easing: transitionTimingFunction,
            }),
        ].join(',');
        node.style.opacity = '0';
        if (onExit) {
            onExit(node);
        }
    });
    const handleExited = normalizedTransitionCallback(onExited);
    const handleAddEndListener = (next) => {
        if (timeout === 'auto') {
            timer.start(autoTimeout.current || 0, next);
        }
        if (addEndListener) {
            // Old call signature before `react-transition-group` implemented `nodeRef`
            addEndListener(nodeRef.current, next);
        }
    };
    return (_jsx(Transition, Object.assign({ appear: appear, in: inProp, nodeRef: nodeRef, onEnter: handleEnter, onEntered: handleEntered, onEntering: handleEntering, onExit: handleExit, onExited: handleExited, onExiting: handleExiting, 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        addEndListener: handleAddEndListener, timeout: timeout === 'auto' ? undefined : timeout }, other, { children: (state, childProps) => cloneElement(children, Object.assign({ style: Object.assign(Object.assign({ visibility: state === 'exited' && !inProp ? 'hidden' : undefined }, style), children.props.style), ref: handleRef }, childProps)) })));
});
const Grow = forwardRef(GrowComponent);
export default Grow;
